import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from "@material-ui/core/Checkbox";
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { municipalities } from "../helpers/Municipalities.js";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import StoreIcon from '@mui/icons-material/Store';
import SellIcon from '@mui/icons-material/Sell';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import firebase from "./../helpers/Firestore";

const analytics = firebase.analytics();
const remoteConfig = firebase.remoteConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "75%",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  centerText: {
    textAlign: "center"
  },
  boldUnderlineText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: "center",
    fontSize: "12px"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Input = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if(newValue == 0){
      analytics.logEvent('pressed_buying_tab');
    }else if(newValue == 1){
      analytics.logEvent('pressed_selling_tab');
    }else if(newValue == 2){
      analytics.logEvent('pressed_refinance_tab');
    }
    setValue(newValue);
    props.setTab(newValue);
  };

  return (
    <div className="container">
      <div className="input-container2">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab icon={<StoreIcon />} label={remoteConfig.getValue('buying_label').asString()} {...a11yProps(0)}/>
          <Tab icon={<SellIcon />} label={remoteConfig.getValue('selling_label').asString()}  {...a11yProps(1)}/>
          <Tab icon={<AttachMoneyIcon />} label={remoteConfig.getValue('refinance_label').asString()}  {...a11yProps(2)}/>
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
      <div className="input-container">
        <CurrencyTextField
          className={classes.textField}
          label={remoteConfig.getValue('buying_price').asString()}
          value={props.price}
          onChange={(event, price) => props.handleSetPrice(price)}
          variant="outlined"
          currencySymbol="$"
          minimumValue="0"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          error={props.priceErrorText !== ""}
          helperText={props.priceErrorText}
          modifyValueOnWheel={false}
        />
      </div>
      <div className="input-container">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('location_label').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('location_label').asString()}
            value={props.municipality}
            onChange={(event) => props.handleSetMunicipality(event)}
          >
            <option aria-label="none" value={""} />
            {municipalities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <div className="spacing" />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('buying_purchasers').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('buying_purchasers').asString()}
            value={props.purchasers}
            onChange={(event) => props.handleSetPurchasers(event)}
          >
            <option value={"1"}>1</option>
            <option value={"2"}>2</option>
            <option value={"3"}>3+</option>
          </Select>
        </FormControl>
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.mortgage}
              onChange={props.handleSetMortgage}
              name="mortgage"
              color="primary"
            />
          }
          label={remoteConfig.getValue('buying_mortgage').asString()}
        />
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.strata}
              onChange={props.handleSetStrata}
              name="strata"
              color="primary"
            />
          }
          label={remoteConfig.getValue('buying_strata').asString()}
        />
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.newConstruction}
              onChange={props.handleSetNewConstruction}
              name="construction"
              color="primary"
            />
          }
          label={remoteConfig.getValue('buying_newly_constructed').asString()}
        />
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.firstTimeBuyer}
              onChange={props.handleSetFirstTimeBuyer}
              name="strata"
              color="primary"
            />
          }
          label={<div>{remoteConfig.getValue('buying_homebuyer_text').asString()}<a href="https://www2.gov.bc.ca/gov/content/taxes/property-taxes/property-transfer-tax/exemptions/first-time-home-buyers">{remoteConfig.getValue('buying_homebuyer_url').asString()}</a></div>}
        />
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleCalculate}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pmc').asString()}
        </Button>
      </div>
      </TabPanel>
      
      <TabPanel value={value} index={1}>
      <div className="input-container">
        <CurrencyTextField
          className={classes.textField}
          label={remoteConfig.getValue('selling_price').asString()}
          value={props.sellingPrice}
          onChange={(event, selling_price) => props.handleSetSellingPrice(selling_price)}
          variant="outlined"
          currencySymbol="$"
          minimumValue="0"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          error={props.sellingPriceErrorText !== ""}
          helperText={props.sellingPriceErrorText}
          modifyValueOnWheel={false}
        />
      </div>
      <div className="input-container">
      <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('location_label').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('location_label').asString()}
            value={props.sellingMunicipality}
            onChange={(event) => props.handleSetSellingMunicipality(event)}
          >
            <option aria-label="none" value={""} />
            {municipalities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </Select>
        </FormControl>
        </div>
        <div className="input-container">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('selling_sellers').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('selling_sellers').asString()}
            value={props.sellers}
            onChange={(event) => props.handleSetSellers(event)}
          >
            <option value={"1"}>1</option>
            <option value={"2"}>2</option>
            <option value={"3"}>3+</option>
          </Select>
          <FormHelperText>{remoteConfig.getValue('selling_sellers_info').asString()}</FormHelperText>
        </FormControl>
        <div className="spacing" />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            Number of debts?
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('selling_debts').asString()}
            value={props.sellingCharges}
            onChange={(event) => props.handleSetSellingCharges(event)}
          >
            <option value={"0"}>0</option>
            <option value={"1"}>1</option>
            <option value={"2"}>2</option>
            <option value={"3"}>3+</option>
          </Select>
          <FormHelperText>{remoteConfig.getValue('selling_debts_info1').asString()}<b>{remoteConfig.getValue('selling_debts_info2').asString()}</b>{remoteConfig.getValue('selling_debts_info3').asString()}</FormHelperText>
        </FormControl>
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.sellingMortgage}
              onChange={props.handleSetSellingMortgage}
              name="mortgage"
              color="primary"
            />
          }
          label={remoteConfig.getValue('selling_mortgage').asString()}
        />
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleSellingCalculate}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pmc').asString()}
        </Button>
      </div>
      </TabPanel>
      
      <TabPanel value={value} index={2}>
      <div className="input-container">
        <CurrencyTextField
          className={classes.textField}
          label={remoteConfig.getValue('refinance_price').asString()}
          value={props.refinancePrice}
          onChange={(event, price) => props.handleSetRefinancePrice(price)}
          variant="outlined"
          currencySymbol="$"
          minimumValue="0"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          error={props.refinancePriceErrorText !== ""}
          helperText={props.refinancePriceErrorText}
          modifyValueOnWheel={false}s
        />
      </div>
      <div className="input-container">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('location_label').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('location_label').asString()}
            value={props.refinanceMunicipality}
            onChange={(event) => props.handleSetRefinanceMunicipality(event)}
          >
            <option aria-label="none" value={""} />
            {municipalities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="input-container">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('refinance_borrowers').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('refinance_borrowers').asString()}
            value={props.borrowers}
            onChange={(event) => props.handleSetBorrowers(event)}
          >
            <option value={"1"}>1</option>
            <option value={"2"}>2</option>
            <option value={"3"}>3+</option>
          </Select>
          <FormHelperText>{remoteConfig.getValue('refinance_borrowers_text').asString()}</FormHelperText>
        </FormControl>
        <div className="spacing" />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            {remoteConfig.getValue('refinance_debts').asString()}
          </InputLabel>
          <Select
            native
            label={remoteConfig.getValue('refinance_debts').asString()}
            value={props.refinanceCharges}
            onChange={(event) => props.handleSetRefinanceCharges(event)}
          >
            <option value={"0"}>0</option>
            <option value={"1"}>1</option>
            <option value={"2"}>2</option>
            <option value={"3"}>3+</option>
          </Select>
          <FormHelperText>{remoteConfig.getValue('refinance_debts_info1').asString()}<b>{remoteConfig.getValue('refinance_debts_info2').asString()}</b>{remoteConfig.getValue('refinance_debts_info3').asString()}</FormHelperText>
        </FormControl>
      </div>

      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.refinanceStrata}
              onChange={props.handleSetRefinanceStrata}
              name="strata"
              color="primary"
            />
          }
          label={remoteConfig.getValue('refinance_strata').asString()}
        />
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleRefinanceCalculate}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pmc').asString()}
        </Button>
      </div>
      </TabPanel>

      <div className="flex-container">
        <Typography component="p" variant="inherit" dangerouslySetInnerHTML={{ __html: remoteConfig.getValue('input_footer_text').asString() }} />
      </div>
    </div>
  );
};

export default Input;
